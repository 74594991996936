<template>
  <b-modal
    id="call-record-modal"
    ref="call-record-modal"
    title="Call Record"
    centered
    hide-footer
    @show="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <b-row>
      <b-col cols="12">
        <HeroInputText
          id="submitted-date"
          v-model="leadsManagement.submitted_time"
          label="Submitted Date"
          column="4"
          :readonly="true"
        />
      </b-col>

      <b-col cols="12">
        <HeroInputText
          id="did-number"
          v-model="leadsManagement.trackingPhone"
          label="DID Number"
          column="4"
          :readonly="true"
        />
      </b-col>

      <b-col cols="12">
        <HeroInputText
          id="phone-number"
          v-model="leadsManagement.tel"
          label="Phone Number"
          column="4"
          :readonly="true"
        />
      </b-col>

      <b-col cols="12" class="text-center">
        <hr>
        <audio
          :src="audioSource" type="audio/mpeg"
          autoplay
          preload="auto"
          controls
          controlsList="nodownload"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    HeroInputText,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    leadsManagement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      audioId: '',
      audioSource: '',
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['call-record-modal'].hide()
    },

    async doLoadData() {
      try {
        if (this.campaignId && this.leadsManagement.phone_data.recording_id) {
          this.showOverlay = true

          // แยก Audio Id ออกจากชื่อไฟล์บน S3
          let tempId = this.leadsManagement.phone_data.recording_id
          tempId = tempId.replace('https://heroleads-audio.s3.ap-southeast-1.amazonaws.com/thailand/', '')
          tempId = tempId.replace('https://heroleads-audio.s3.ap-southeast-1.amazonaws.com/indonesia/', '')
          tempId = tempId.replace('.mp3', '')
          this.audioId = tempId

          const url = `/call-record/get-public-call-record-link/${this.leadsManagement.phone_data.recording_id}?campaignId=${this.campaignId}`
          const response = await axiosInstance.get(url)

          this.audioSource = response.data.link
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>
