<template>
  <b-modal
    id="offline-conversion-list-modal"
    ref="offline-conversion-list-modal"
    title="Offline Conversion List"
    centered
    hide-footer
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="formA">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="start date"
              rules="required"
            >
              <HeroInputDate
                id="start-date"
                v-model="startDate"
                label="Start Date"
                column="4"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="start date"
              rules="required"
            >
              <HeroInputDate
                id="end-date"
                v-model="endDate"
                label="End Date"
                column="4"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="start date"
              rules="required"
            >
              <HeroVueSelect
                id="date-type"
                v-model="dateType"
                label="Date Type"
                column="4"
                :multiple="false"
                :clearable="false"
                :options="dateTypeOptions"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row>
      <b-col cols="12">
        <HeroButtonAction type="button" variant="primary" @click="doExport">
          Save
        </HeroButtonAction>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import moment from 'moment-timezone'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroInputDate,
    HeroVueSelect,
    HeroButtonAction,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      startDate: moment().subtract(1, 'month').format(),
      endDate: moment().format(),
      dateType: 'Submitted Date Time',

      dateTypeOptions: [
        { text: 'Submitted Date Time', value: 'Submitted Date Time' },
        { text: 'Updated Info Time', value: 'Updated Info Time' },
      ],
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['offline-conversion-list-modal'].hide()
    },

    async doExport() {
      try {
        const validationResult = await this.$refs.formA.validate()

        if (validationResult) {
          this.showOverlay = true

          const body = {
            startDate: this.startDate,
            endDate: this.endDate,
            dateType: this.dateType,
          }

          const url = `/herovision/lead-management/export-offline-conversion-list/${this.campaignId}`
          const response = await axiosInstance.post(url, body)

          window.open(response.data.filePath)
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>
