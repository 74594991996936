<template>
  <b-tab lazy title="Lead Management">
    <div class="filter-section d-flex justify-content-between flex-wrap gap-4 mb-2">
      <div class="d-flex flex-wrap gap-4">
        <HeroInputText
          id="filter"
          v-model="tableConfig.filter"
          class="hero-vision-search mb-0"
          placeholder="Search"
          @input="doTableFilter(500)"
        />

        <div class="d-flex col-gap-4">
          <!-- Filter Dropdown -->
          <b-dropdown
            id="dropdown-filters"
            ref="dropdownFilters"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            text="Types"
            left
            block
          >
            <b-dropdown-form style="width: 300px;">
              <HeroCheckbox
                id="filters-submitted"
                v-model="filters.submitted"
                label="Submitted"
              />

              <HeroCheckbox
                id="filters-answered"
                v-model="filters.answered"
                label="Answered"
              />

              <HeroCheckbox
                id="filters-missed-call"
                v-model="filters.missedCall"
                label="Missed Call"
              />

              <HeroCheckbox
                id="filters-chatbot"
                v-model="filters.chatbot"
                label="Chatbot"
              />

              <b-dropdown-divider />
              <b-row>
                <b-col cols="6">
                  <HeroButtonAction
                    v-if="filtersButtonSwitch === 'clear'"
                    type="button"
                    variant="primary"
                    @click="selectNoFilters"
                  >
                    Clear
                  </HeroButtonAction>
                  <HeroButtonAction
                    v-if="filtersButtonSwitch === 'all'"
                    type="button"
                    variant="primary"
                    @click="selectAllFilters"
                  >
                    All
                  </HeroButtonAction>
                </b-col>
                <b-col cols="6">
                  <HeroButtonAction
                    type="button"
                    variant="primary"
                    @click="doLoadData"
                  >
                    Search
                  </HeroButtonAction>
                </b-col>
              </b-row>
            </b-dropdown-form>
          </b-dropdown>

          <!-- Status Dropdown -->
          <b-dropdown
            id="dropdown-status"
            ref="dropdownStatus"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            text="Status"
            right
            block
          >
            <b-dropdown-form style="width: 300px;">
              <HeroCheckbox
                v-for="item in status"
                :id="item.id"
                :key="item.id"
                v-model="item.selected"
                :label="item.name"
              />
              <b-dropdown-divider />
              <b-row>
                <b-col cols="6">
                  <HeroButtonAction
                    v-if="statusButtonSwitch === 'clear'"
                    type="button"
                    variant="primary"
                    @click="selectNoStatus"
                  >
                    Clear
                  </HeroButtonAction>
                  <HeroButtonAction
                    v-if="statusButtonSwitch === 'all'"
                    type="button"
                    variant="primary"
                    @click="selectAllStatus"
                  >
                    All
                  </HeroButtonAction>
                </b-col>
                <b-col cols="6">
                  <HeroButtonAction
                    type="button"
                    variant="primary"
                    @click="doLoadData"
                  >
                    Search
                  </HeroButtonAction>
                </b-col>
              </b-row>
              <b-dropdown-divider />
              <b-row>
                <b-col cols="12">
                  <HeroButtonModal
                    v-b-modal="'edit-status-modal'"
                    type="button"
                    variant="primary"
                    :disabled="!$can('Edit Status', 'HeroVisionX > Lead Management')"
                  >
                    Add/Edit Status
                  </HeroButtonModal>
                </b-col>
              </b-row>
            </b-dropdown-form>
          </b-dropdown>

          <!-- ปุ่ม Custom Column -->
          <b-dropdown
            id="dropdown-columns"
            ref="dropdownColumns"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            text="Columns"
            right
            block
          >
            <b-dropdown-form style="width: 300px;">
              <HeroCheckbox
                v-for="item in customColumns"
                :id="item.id"
                :key="item.id"
                v-model="item.selected"
                :label="item.name"
              />
              <b-dropdown-divider />
              <b-row>
                <b-col cols="6">
                  <HeroButtonAction
                    v-if="customColumnsButtonSwitch === 'clear'"
                    type="button"
                    variant="primary"
                    @click="selectNoCustomColumns"
                  >
                    Clear
                  </HeroButtonAction>
                  <HeroButtonAction
                    v-if="customColumnsButtonSwitch === 'all'"
                    type="button"
                    variant="primary"
                    @click="selectAllCustomColumns"
                  >
                    Select All
                  </HeroButtonAction>
                </b-col>
                <b-col cols="6">
                  <HeroButtonAction
                    type="button"
                    variant="primary"
                    @click="doRefreshTableColumns"
                  >
                    Select
                  </HeroButtonAction>
                </b-col>
              </b-row>
            </b-dropdown-form>
          </b-dropdown>
        </div>

        <!-- ปุ่ม HeroVision Filter -->
        <HeroVueSelect
          id="heroVisionFilterId"
          v-model="heroVisionFilterId"
          class="hero-vision-filter m-0"
          :clearable="true"
          :options="heroVisionFilterOptions"
          placeholder="Filters"
          @input="doLoadData"
        />
      </div>

      <div class="d-flex justify-content-between gap-4">
        <!-- ตัวเลือก Per Page -->
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          @input="doTableFilter(100)"
        />

        <!-- ปุ่ม Download -->
        <b-dropdown
          v-if="isDownloadDropdownShow"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          text="Download"
          right
          block
        >
          <b-dropdown-item
            v-if="$can('Download - Leads', 'HeroVisionX > Lead Management')"
            v-b-modal="'download-leads-modal'"
          >
            Leads
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('Download - Offline Conversion List', 'HeroVisionX > Lead Management') && userType === 'Internal'"
            v-b-modal="'offline-conversion-list-modal'"
          >
            Offline Conversion List
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <b-row>
      <b-col cols="12">
        <b-table
          id="herovision-lead-management-table"
          show-empty
          striped
          sticky-header="100%"
          :small="true"
          :responsive="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
        >
          <template #cell(action)="lead" style="width: 60px;">
            <HeroButtonModal
              v-b-modal="'edit-lead-modal'"
              type="button"
              variant="primary"
              style="width: 60px;"
              @click="doEditLead(lead.item)"
            >
              <feather-icon icon="EditIcon" />
            </HeroButtonModal>
          </template>

          <template #cell(type)="lead">
            <!-- ลีดประเภท Submitted -->
            <div
              v-if="lead.item.type_order === 'submitted'"
            >
              <b-avatar
                variant="light-primary"
                :badge="lead.item.child_count > 1 ? String(lead.item.child_count) : false"
                badge-variant="danger"
              >
                <span class="d-flex align-items-center">
                  <feather-icon
                    icon="LayoutIcon"
                    size="20"
                  />
                </span>
              </b-avatar>
            </div>

            <!-- ลีดประเภท Answered Call -->
            <div
              v-if="lead.item.type_order === 'answeredCall'"
            >
              <b-avatar
                variant="light-primary"
                :badge="lead.item.child_count > 1 ? String(lead.item.child_count) : false"
                badge-variant="danger"
              >
                <span class="d-flex align-items-center">
                  <feather-icon
                    icon="PhoneIncomingIcon"
                    size="20"
                  />
                </span>
              </b-avatar>
            </div>

            <!-- ลีดประเภท Missed Call -->
            <div
              v-if="lead.item.type_order === 'missedCall'"
            >
              <b-avatar
                variant="light-primary"
                :badge="lead.item.child_count > 1 ? String(lead.item.child_count) : false"
                badge-variant="danger"
              >
                <span class="d-flex align-items-center">
                  <feather-icon
                    icon="PhoneMissedIcon"
                    size="20"
                  />
                </span>
              </b-avatar>
            </div>

            <!-- ลีดประเภท Chatbot -->
            <div
              v-if="lead.item.type_order === 'chatbot'"
            >
              <b-avatar
                variant="light-primary"
                :badge="lead.item.child_count > 1 ? String(lead.item.child_count) : false"
                badge-variant="danger"
              >
                <span class="d-flex align-items-center">
                  <feather-icon
                    icon="MessageCycleIcon"
                    size="20"
                  />
                </span>
              </b-avatar>
            </div>
          </template>

          <!--
          <template #cell(name)="data">
            <HeroInputText
              :id="`name-${data.item.id}`"
              v-model="data.item.name"
              class="m-0"
              :clearable="false"
              @focusout="doUpdateLead(data.item)"
            />
          </template>

          <template #cell(email)="data">
            <HeroInputText
              :id="`tel-${data.item.id}`"
              v-model="data.item.email"
              class="m-0"
              :clearable="false"
              @focusout="doUpdateLead(data.item)"
            />
          </template>

          <template #cell(tel)="data">
            <HeroInputText
              :id="`tel-${data.item.id}`"
              v-model="data.item.tel"
              class="m-0"
              :clearable="false"
              @focusout="doUpdateLead(data.item)"
            />
          </template>
          -->

          <template #cell(name)="lead">
            {{ lead.item.name }}
          </template>

          <template #cell(email)="lead">
            {{ lead.item.email }}
          </template>

          <template #cell(tel)="lead">
            {{ lead.item.tel }}
          </template>

          <template #cell(submitted_time)="lead">
            {{ lead.item.submitted_time }}
          </template>

          <template #cell(remark)="lead">
            {{ lead.item.comment_data[0].comment }}
          </template>

          <template #cell(content)="lead">
            <div
              v-b-popover.hover.html="popoverCustomerAttribute(lead.item.content)"
              class="cursor-pointer"
              no-fade
            >
              {{ formatCustomerAttribute(lead.item.content) }}
            </div>
          </template>

          <template #cell(referrer)="lead">
            <HeroVueSelect
              :id="`referrer-${lead.item.id}`"
              v-model="lead.item.referrer_id"
              class="m-0"
              :clearable="false"
              :options="referrerOptions"
              @input="doUpdateLead(lead.item)"
            />
          </template>

          <template #cell(status)="lead">
            <HeroVueSelect
              v-if="$can('Edit Status', 'HeroVisionX > Lead Management')"
              :id="`status-${lead.item.id}`"
              v-model="lead.item.crm_customer_status_id"
              class="m-0"
              :clearable="false"
              :options="statusOptions"
              @input="doUpdateLead(lead.item)"
            />

            <div v-else>
              {{ lead.item.crm_customer_status_name }}
            </div>
          </template>

          <template #cell(call_record)="lead">
            <HeroButtonModal
              v-if="lead.item.type === 'phone' && lead.item.phone_data.recording_id"
              v-b-modal="'call-record-modal'"
              type="button"
              variant="primary"
              :disabled="!$can('Listen Call Record', 'HeroVisionX > Lead Management')"
              @click="doListenCallRecord(lead.item)"
            >
              <feather-icon icon="PlayIcon" />
            </HeroButtonModal>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>

    <EditStatusModal :campaign-id="data.filter.campaignId" @success="doLoadData" />
    <EditLeadModal :campaign-id="data.filter.campaignId" :leads-management="leadsManagement" :status="status" :customer-attribute-list="customerAttributeList" @success="doLoadData" />
    <CallRecordModal :campaign-id="data.filter.campaignId" :leads-management="leadsManagement" />
    <OfflineConversionListModal :campaign-id="data.filter.campaignId" />
    <DownloadLeadsModal :filter="data.filter" />
  </b-tab>
</template>

<script>
import moment from 'moment-timezone'
import ErrorService from '@/services/ErrorService'
import SweetAlert from '@/services/SweetAlert'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import EditStatusModal from '@/views/herovision/components/LeadManagement/EditStatusModal.vue'
import EditLeadModal from '@/views/herovision/components/LeadManagement/EditLeadModal.vue'
import CallRecordModal from '@/views/herovision/components/LeadManagement/CallRecordModal.vue'
import OfflineConversionListModal from '@/views/herovision/components/LeadManagement/OfflineConversionListModal.vue'
import DownloadLeadsModal from '@/views/herovision/components/LeadManagement/DownloadLeadsModal.vue'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroVueSelect,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    HeroButtonModal,
    HeroButtonAction,
    HeroInputText,
    HeroCheckbox,
    EditStatusModal,
    EditLeadModal,
    CallRecordModal,
    OfflineConversionListModal,
    DownloadLeadsModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      leadsManagement: {},
      editStatus: {},
      customerAttributeList: '',
      userType: this.$store.getters['heroAiAuthentications/getUserType'],

      filters: {
        submitted: true,
        answered: true,
        missedCall: true,
        chatbot: true,
      },
      status: [],
      statusSelected: [],
      columns: [],
      heroVisionFilterId: '',
      heroVisionFilterOptions: [],

      referrerOptions: [],
      statusOptions: [],

      customColumns: [],

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        mandatoryFields1: [
          {
            label: 'Action',
            key: 'action',
            sortable: false,
          },
          {
            label: 'Type',
            key: 'type',
            stickyColumn: false,
          },
          {
            label: 'Date',
            key: 'submitted_time',
            sortable: false,
          },
          {
            label: 'Name',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Email',
            key: 'email',
            sortable: false,
          },
          {
            label: 'Phone',
            key: 'tel',
            sortable: false,
          },
          {
            label: 'Filter',
            key: 'filter',
            sortable: false,
          },
          {
            label: 'Content',
            key: 'content',
            sortable: false,
          },
        ],
        mandatoryFields2: [
          {
            label: 'Remark',
            key: 'remark',
            sortable: false,
          },
          {
            label: 'Source',
            key: 'referrer',
            sortable: false,
          },
          {
            label: 'Status',
            key: 'status',
            sortable: false,
          },
          {
            label: 'Record',
            key: 'call_record',
            sortable: false,
          },
        ],
        customFields: [],
        fields: [],
      },
    }
  },
  computed: {
    demoAccount() {
      return this.$store.getters['heroAiAuthentications/loginData'].demo_account
    },

    customColumnsButtonSwitch() {
      let falseCount = 0

      const customColumnCount = this.customColumns.length

      this.customColumns.forEach(column => {
        if (column.selected === false) {
          falseCount += 1
        }
      })

      if (falseCount === customColumnCount) {
        return 'all'
      }

      return 'clear'
    },

    filtersButtonSwitch() {
      let button = 'clear'

      if (
        this.filters.submitted === false
          && this.filters.answered === false
          && this.filters.missedCall === false
          && this.filters.chatbot === false
      ) {
        button = 'all'
      }

      return button
    },

    statusButtonSwitch() {
      let falseCount = 0

      const statusCount = this.status.length

      this.status.forEach(status => {
        if (status.selected === false) {
          falseCount += 1
        }
      })

      if (falseCount === statusCount) {
        return 'all'
      }

      return 'clear'
    },

    campaignId() {
      return this.data.filter.campaignId
    },

    isDownloadDropdownShow() {
      return (
        this.$can('Download - Leads', 'HeroVisionX > Lead Management')
        || this.$can('Download - Offline Conversion List', 'HeroVisionX > Lead Management')
      )
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.items = newData.leadManagement.data
        this.tableConfig.totalRows = Number(newData.leadManagement.total)

        this.referrerOptions = []

        newData.referrer.forEach(referrer => {
          this.referrerOptions.push({
            text: referrer.name,
            value: referrer.id,
          })
        })

        let selected
        // สร้างสถานะ New Leads
        if (this.statusSelected.length === 0) {
          selected = true
        } else {
          selected = !!this.statusSelected.includes('0')
        }

        this.status = [
          {
            id: '0',
            name: 'New Leads',
            outside_funnel_flag: false,
            percent: 0,
            selected,
          },
        ]

        this.statusOptions = [
          { text: 'New Leads', value: '0' },
        ]

        // รวมสถานะที่ลูกค้าเป็นคนสร้างเข้าด้วยกัน
        newData.status.forEach(status => {
          if (this.statusSelected.length === 0) {
            selected = true
          } else {
            selected = !!this.statusSelected.includes(status.id)
          }

          this.status.push({
            id: status.id,
            name: status.name,
            outside_funnel_flag: status.outside_funnel_flag,
            percent: status.percent,
            selected,
          })

          this.statusOptions.push({
            text: status.name,
            value: status.id,
          })
        })

        this.customColumns = []

        // สร้างรายการ Custom Columns
        this.doCreateCustomColumns(newData.leadManagement.data)
        // ดึงรายการ Custom Columns จาก LocalStorage
        this.doLoadCustomColumns()
        // อัพเดตคอลัมน์ของตาราง
        this.doUpdateTableColumns()

        this.items = newData.leadManagement.data
        this.hiddenMessage = ''
        this.heroVisionFilterOptions = []

        newData.heroVisionFilter.forEach(heroVisionFilter => {
          this.heroVisionFilterOptions.push({
            text: heroVisionFilter.name,
            value: heroVisionFilter.id,
          })
        })

        const newHeroVisionFilters = newData.heroVisionFilter.filter(option => option.id === this.heroVisionFilterId)

        if (newHeroVisionFilters.length === 0) {
          this.heroVisionFilterId = ''
        }
      },
    },

    campaignId() {
      this.statusSelected = []
      this.$store.commit('LeadManagement/setHeroVisionFilter', '')
    },
  },
  created() {
    this.$store.commit('LeadManagement/setDefaultState')
  },
  methods: {
    moment() {
      return moment()
    },

    // eslint-disable-next-line no-unused-vars
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    async doLoadData() {
      this.$store.commit('LeadManagement/setCurrentPage', this.tableConfig.currentPage)
      this.$store.commit('LeadManagement/setPerPage', this.tableConfig.perPage)
      this.$store.commit('LeadManagement/setSearchText', this.tableConfig.filter)
      this.$store.commit('LeadManagement/setFilters', this.filters)
      this.$store.commit('LeadManagement/setStatus', this.status)
      this.$store.commit('LeadManagement/setHeroVisionFilter', this.heroVisionFilterId)

      this.statusSelected = []
      this.status.forEach(item => {
        if (item.selected) {
          this.statusSelected.push(item.id)
        }
      })

      this.$emit('refresh-data')
    },

    doCovertCustomColumnName(item) {
      let name = ''
      name = item.replace('ca_', '')
      name = name.toLowerCase().replace(
        /([-_][a-z])/g,
        group => group.toUpperCase()
          .replace('-', ' ')
          .replace('_', ' '),
      )

      return name.charAt(0).toUpperCase() + name.substring(1)
    },

    doCreateCustomColumns(leadData) {
      // หารายชื่อ Custom Columns จากลีดแรก ถ้าไม่มีลีดแรกให้ส่งกลับค่าว่างไป
      if (leadData && leadData[0]) {
        const firstLead = leadData[0]

        // สร้างอาร์เรย์ตามรายชื่อคอลัมน์ของลีด
        const objectKeys = Object.keys(firstLead)

        // เรียงรายชื่อคอลัมน์ตามตัวอักษร
        const sortedObjectKeys = objectKeys.toSorted((a, b) => a.localeCompare(b))

        // เลือกเฉพาะคอลัมน์ที่ขึ้นต้นด้วย ca_ มาเป็น Custom Columns
        sortedObjectKeys.forEach(key => {
          if (key.includes('ca_')) {
            const name = this.doCovertCustomColumnName(key)

            this.customColumns.push({
              id: key,
              name,
              selected: false,
            })
          }
        })
      }
    },

    doUpdateTableColumns() {
      this.tableConfig.fields = []
      this.tableConfig.customFields = []

      this.customColumns.forEach(column => {
        if (column.selected) {
          this.tableConfig.customFields.push({
            label: column.name,
            key: column.id,
            sortable: false,
          })
        }
      })

      this.tableConfig.fields.push(...this.tableConfig.mandatoryFields1)
      this.tableConfig.fields.push(...this.tableConfig.customFields)
      this.tableConfig.fields.push(...this.tableConfig.mandatoryFields2)
    },

    doSaveCustomColumns() {
      const selectedColumns = []

      this.customColumns.forEach(column => {
        if (column.selected) {
          selectedColumns.push(column.id)
        }
      })

      localStorage.setItem(`custom-columns-for-campaign-${this.data.filter.campaignId}`, JSON.stringify(selectedColumns))
    },

    doLoadCustomColumns() {
      const selectedColumns = JSON.parse(localStorage.getItem(`custom-columns-for-campaign-${this.data.filter.campaignId}`))
      const newCustomColumns = []

      if (selectedColumns && selectedColumns.length > 0) {
        this.customColumns.forEach(column => {
          newCustomColumns.push({
            id: column.id,
            name: column.name,
            selected: selectedColumns.includes(column.id),
          })
        })

        this.customColumns = newCustomColumns
      }
    },

    doRefreshTableColumns() {
      this.doSaveCustomColumns()
      this.doUpdateTableColumns()
      this.$refs.dropdownColumns.hide()
      this.$root.$emit('bv::refresh::table', 'herovision-lead-management-table')
    },

    selectAllCustomColumns() {
      const newCustomColumns = []

      this.customColumns.forEach(column => {
        newCustomColumns.push({
          id: column.id,
          name: column.name,
          selected: true,
        })
      })

      this.customColumns = newCustomColumns
    },

    selectNoCustomColumns() {
      const newCustomColumns = []

      this.customColumns.forEach(column => {
        newCustomColumns.push({
          id: column.id,
          name: column.name,
          selected: false,
        })
      })

      this.customColumns = newCustomColumns
    },

    selectNoFilters() {
      this.filters.submitted = false
      this.filters.answered = false
      this.filters.missedCall = false
      this.filters.chatbot = false
    },

    selectAllFilters() {
      this.filters.submitted = true
      this.filters.answered = true
      this.filters.missedCall = true
      this.filters.chatbot = true
    },

    selectNoStatus() {
      const newStatus = []

      this.status.forEach(status => {
        newStatus.push({
          id: status.id,
          name: status.name,
          outside_funnel_flag: status.outside_funnel_flag,
          percent: status.percent,
          selected: false,
        })
      })

      this.status = newStatus
    },

    selectAllStatus() {
      const newStatus = []

      this.status.forEach(status => {
        newStatus.push({
          id: status.id,
          name: status.name,
          outside_funnel_flag: status.outside_funnel_flag,
          percent: status.percent,
          selected: true,
        })
      })

      this.status = newStatus
    },

    popoverCustomerAttribute(content) {
      let result = ''

      // eslint-disable-next-line no-restricted-syntax
      for (const key in content) {
        if (!content[key] || !content[key].length) {
          // eslint-disable-next-line no-continue
          continue
        }

        // Convert snake case to camel case.
        let title = key.toLowerCase().replace(
          /([-_][a-z])/g,
          group => group.toUpperCase()
            .replace('-', ' ')
            .replace('_', ' '),
        )

        // Uppercase for first character.
        title = title.charAt(0).toUpperCase() + title.substring(1)

        if (content[key] instanceof Array) {
          result += `<b>${title}:</b> ${content[key].join(', ')}<br>`
        } else {
          result += `<b>${title}:</b> ${content[key]}<br>`
        }
      }

      result += ''

      return result
    },

    formatCustomerAttribute(content) {
      let result = ''
      const count = 1

      // eslint-disable-next-line no-restricted-syntax
      for (const key in content) {
        if (!content[key] || !content[key].length) {
          // eslint-disable-next-line no-continue
          continue
        }

        if (count > 3) {
          break
        }

        // Convert snake case to camel case.
        let title = key.toLowerCase().replace(
          /([-_][a-z])/g,
          group => group.toUpperCase()
            .replace('-', ' ')
            .replace('_', ' '),
        )

        // Uppercase for first character.
        title = title.charAt(0).toUpperCase() + title.substring(1)

        if (content[key] instanceof Array) {
          result += `${title}: ${content[key].join(', ')} `
        } else {
          result += `${title}: ${content[key]} `
        }
      }

      return `${result.slice(0, 25)}...`
    },

    doEditLead(item) {
      this.leadsManagement = item
      this.customerAttributeList = this.popoverCustomerAttribute(item.content)
    },

    async doUpdateLead(item) {
      try {
        this.showOverlay = true

        const body = {
          name: item.name,
          ...!this.demoAccount && {
            email: item.email,
            tel: item.tel,
          },
          crm_customer_status_id: item.crm_customer_status_id,
          referrer_id: item.referrer_id,
        }

        // ส่งข้อมูลไปบันทึกที่ Lead Management
        const response = await axiosInstance.put(`/herovision/lead-management/update/${item.id}`, body)

        await this.$swal({ ...SweetAlert.success, text: response.data.message })
        await this.doLoadData()
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    doListenCallRecord(item) {
      this.leadsManagement = item
    },

    // async doExportLeads() {
    //   try {
    //     this.showOverlay = true
    //
    //     let query = 'export_type=excel&'
    //
    //     // TODO: แก้ไขให้ Filter เป็นไปตามหน้าจอ
    //     query += `start_date=${this.data.filter.fromDate} 00:00:00&`
    //     query += `end_date=${this.data.filter.toDate} 23:59:59&`
    //     query += 'types[0]=submitted&'
    //     query += 'types[1]=answered&'
    //     query += 'types[2]=missed-call&'
    //     query += 'types[3]=chatbot'
    //
    //     const url = `/herovision/lead-management/export-leads/${this.data.filter.campaignId}?${query}`
    //     const response = await axiosInstance.get(url)
    //
    //     window.open(response.data.filePath)
    //   } catch (error) {
    //     await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
    //
    //     if (error.response.status === 401) {
    //       await this.$router.push({ name: 'heroai-logout' })
    //     }
    //   } finally {
    //     this.showOverlay = false
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
.filter-section {
  flex-direction: column-reverse;
  @media(min-width: 1401px) {
    flex-direction: row;
  }

  div:first-child {
    flex-direction: column;
    @media(min-width: 720px) {
      flex-direction: row;
    }
    .hero-vision-search {
      width: 100%;
      @media(min-width: 992px) {
        flex: 1 0 0%;
      }
      @media(min-width: 1401px) {
        width: 370px;
      }
    }

    .hero-vision-filter {
      flex: 1 0 0%;
      width: auto;
      @media(min-width: 992px) {
        width: 180px;
      }
    }
  }
}
</style>
