<template>
  <b-modal
    id="edit-lead-modal"
    ref="edit-lead-modal"
    title="Lead Information"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    @shown="doLoadData"
    @close="doCloseModal"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <!-- Lead Detail -->
    <validation-observer ref="formA">
      <b-form @submit.stop.prevent>
        <b-row class="mb-2">

          <!-- Lead Form -->
          <b-col cols="6">
            <b-row>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="first name"
                  rules=""
                >
                  <HeroInputText
                    id="first-name"
                    v-model="editFirstName"
                    label="First Name"
                    column="4"
                    :required="false"
                    :clearable="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="last name"
                  rules=""
                >
                  <HeroInputText
                    id="last-name"
                    v-model="editLastName"
                    label="Last Name"
                    column="4"
                    :required="false"
                    :clearable="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules=""
                >
                  <HeroInputText
                    id="email"
                    v-model="editEmail"
                    label="Email"
                    column="4"
                    :required="false"
                    :clearable="true"
                    :disabled="!!demoAccount"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules=""
                >
                  <HeroInputText
                    id="phone"
                    v-model="editPhone"
                    label="Phone"
                    column="4"
                    :required="false"
                    :clearable="true"
                    :disabled="!!demoAccount"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules=""
                >
                  <HeroVueSelect
                    id="status"
                    v-model="editStatus"
                    label="Status"
                    column="4"
                    :options="statusOptions"
                    :required="false"
                    :clearable="false"
                    :disabled="!$can('Edit Status', 'HeroVisionX > Lead Management')"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>

              <b-col cols="4" offset="8">
                <HeroButtonAction
                  type="button"
                  variant="primary"
                  block
                  @click="updateLead"
                >
                  Save
                </HeroButtonAction>
              </b-col>
            </b-row>
          </b-col>

          <!-- Customer Attributes -->
          <b-col cols="6">
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="label" style="font-size: 1rem; font-weight: 600;">Submitted Time</span>
              </b-col>
              <b-col cols="8">
                {{ editLead.submitted_time }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="label" style="font-size: 1rem; font-weight: 600;">Updated Time</span>
              </b-col>
              <b-col cols="8">
                {{ editLead.submitted_time_lastest }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="label" style="font-size: 1rem; font-weight: 600;">Content</span>
              </b-col>
              <b-col cols="8">
                <div v-html="customerAttributeList" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Remark -->
    <validation-observer ref="formB">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="10">
            <validation-provider
              #default="{ errors }"
              name="remark"
              rules="required"
            >
              <HeroInputText
                id="remark"
                v-model="newRemark"
                placeholder="New Remark"
                :required="true"
                :clearable="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <HeroButtonAction
              type="button"
              variant="primary"
              block
              @click="addRemark"
            >
              Add
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- History Table -->
    <validation-observer ref="formC">
      <b-form @submit.stop.prevent>
        <b-row class="mb-1">
          <b-col cols="2">
            <b>Date</b>
          </b-col>
          <b-col cols="2">
            <b>Reporter</b>
          </b-col>
          <b-col cols="8">
            <b>Content</b>
          </b-col>
        </b-row>
        <b-row v-for="(remark, index) in editLead.comment_data" :key="index" class="mb-0">
          <b-col cols="2">
            <HeroInputText
              :id="`date-${index}`"
              v-model="remark.updated_at"
              :readonly="true"
            />
          </b-col>
          <b-col cols="2">
            <HeroInputText
              :id="`reporter-${index}`"
              v-model="remark.reporter_name"
              :readonly="true"
            />
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="remark"
              rules="required"
            >
              <HeroInputText
                :id="`content-${index}`"
                v-model="remark.comment"
                :disabled="remark.id === ''"
                :required="true"
                :clearable="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="1">
            <!-- ปุ่มบันทึก Remark -->
            <HeroButtonAction v-if="remark.id" :key="`save-button-${index}`" type="button" variant="primary" @click="updateRemark(remark.id, remark.comment)">
              Save
            </HeroButtonAction>

            <!-- ปุ่มฟังไฟล์บันทึกเสียง -->
            <HeroButtonModal
              v-if="remark.recording_id"
              v-b-modal="'small-call-record-modal'"
              type="button"
              variant="primary"
              :disabled="!$can('Listen Call Record', 'HeroVisionX > Lead Management')"
              @click="doListenCallRecord(remark)"
            >
              <feather-icon icon="PlayIcon" />
            </HeroButtonModal>
          </b-col>
          <b-col cols="1">
            <HeroButtonAction v-if="remark.id" :key="`delete-button-${index}`" type="button" variant="danger" @click="deleteRemark(remark.id)">
              Delete
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <SmallCallRecordModal :campaign-id="campaignId" :remark="listeningRemark" />
  </b-modal>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import SmallCallRecordModal from '@/views/herovision/components/LeadManagement/SmallCallRecordModal.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroVueSelect,
    HeroInputText,
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroButtonAction,
    HeroButtonModal,
    SmallCallRecordModal,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    leadsManagement: {
      type: Object,
      required: true,
    },
    status: {
      type: Array,
      required: true,
    },
    customerAttributeList: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      editLead: {},
      editFirstName: '',
      editLastName: '',
      editPhone: '',
      editEmail: '',
      editStatus: '',
      newRemark: '',
      listeningRemark: {},

      statusOptions: [],

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Date',
            key: 'updated_at',
            stickyColumn: false,
          },
          {
            label: 'Reporter',
            key: 'reporter_name',
            sortable: false,
          },
          {
            label: 'Content',
            key: 'comment',
            sortable: false,
          },
        ],
      },
    }
  },
  computed: {
    demoAccount() {
      return this.$store.getters['heroAiAuthentications/loginData'].demo_account
    },
  },
  methods: {
    doCloseModal() {
      this.statusOptions = []

      this.$refs['edit-lead-modal'].hide()
      this.$emit('success')
    },

    async doLoadData() {
      try {
        this.showOverlay = true

        this.statusOptions = []
        this.status.forEach(eachStatus => {
          this.statusOptions.push({
            text: eachStatus.name,
            value: eachStatus.id,
          })
        })

        // ส่งข้อมูลไปบันทึกที่ Lead Management
        const response = await axiosInstance.get(`/herovision/lead-management/show/${this.leadsManagement.id}`)
        this.editLead = response.data.data

        this.editFirstName = this.editLead.first_name ?? ''
        this.editLastName = this.editLead.last_name ?? ''
        this.editEmail = this.editLead.email ?? ''
        this.editPhone = this.editLead.tel ?? ''
        this.editStatus = this.editLead.crm_customer_status_id
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async updateLead() {
      try {
        const validationResult = await this.$refs.formA.validate()

        if (validationResult) {
          this.showOverlay = true

          const body = {
            first_name: this.editFirstName,
            last_name: this.editLastName,
            ...!this.demoAccount && {
              email: this.editEmail,
              tel: this.editPhone,
            },
            crm_customer_status_id: this.editStatus,
          }

          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axiosInstance.put(`/herovision/lead-management/update/${this.leadsManagement.id}`, body)

          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          await this.doLoadData()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async addRemark() {
      try {
        const validationResult = await this.$refs.formB.validate()
        if (validationResult) {
          this.showOverlay = true

          const body = {
            leadsManagementId: this.leadsManagement.id,
            remark: this.newRemark,
          }

          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axiosInstance.post('/herovision/lead-management/remark', body)

          this.newRemark = ''
          this.$refs.formB.reset()
          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          await this.doLoadData()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async updateRemark(remarkId, remark) {
      try {
        const validationResult = await this.$refs.formC.validate()

        if (validationResult) {
          this.showOverlay = true

          const body = {
            leadsManagementId: this.leadsManagement.id,
            remark,
          }

          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axiosInstance.put(`/herovision/lead-management/remark/${remarkId}`, body)

          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          await this.doLoadData()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async deleteRemark(remarkId) {
      try {
        const validationResult = await this.$refs.formA.validate()

        if (validationResult) {
          this.showOverlay = true

          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axiosInstance.delete(`/herovision/lead-management/remark/${remarkId}/${this.leadsManagement.id}`)

          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          await this.doLoadData()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    doListenCallRecord(remark) {
      this.listeningRemark = remark
    },
  },
}
</script>
